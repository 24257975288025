function isScrolledIntoView(el) {
	var rect = el.getBoundingClientRect();
	var elemTop = rect.top;
	var elemBottom = rect.bottom;
	var isVisible;

	// Only completely visible elements return true:
	// isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);
	// Partially visible elements return true:
	isVisible = elemTop < window.innerHeight && elemBottom >= 0;
	return isVisible;
}

document.addEventListener('DOMContentLoaded', function(){

	const openMenuBtn = document.querySelector('.js-burger'),
				mainMenu = document.querySelector('.h');

	openMenuBtn.addEventListener('click', function(){
		openMenuBtn.classList.toggle('active');
		mainMenu.classList.toggle('opened');
	});


	// go to anchor
	const anchors = document.querySelectorAll('.js-anchor');

	for (let anchor of anchors) {
		anchor.addEventListener('click', function (eventScroll) {
			eventScroll.preventDefault();
			const blockId = anchor.getAttribute("href").substr(1);

			if(mainMenu.classList.contains('opened')) {
				mainMenu.classList.remove('opened');
				openMenuBtn.classList.remove('active');
			}

			const element = document.getElementById(blockId);
			const header = document.querySelector('.h');
			const y = element.getBoundingClientRect().top + window.pageYOffset - header.offsetHeight;

			window.scrollTo({top: y, behavior: 'smooth'});
		});
	};


	// accordion prices
	// const accordion = document.querySelector('.price__list');
	// if(accordion) {
	// 	const accordionItems = accordion.querySelectorAll('.price__list-item');
	// 	let i;

	// 	for (i = 0; i < accordionItems.length; i++) {
	// 		accordionItems[i].addEventListener("click", function() {
	// 			this.classList.toggle("price__list-item--opened");
	// 			var panel = this.querySelector('.price__list-ibox');
	// 			if (panel.style.maxHeight) {
	// 				panel.style.maxHeight = null;
	// 			} else {
	// 				panel.style.maxHeight = panel.scrollHeight + "px";
	// 			} 
	// 		});
	// 	}
	// };


	// map init
	const mapContainer = document.getElementById('mapBody');
	let map;

	ymaps.ready(init);

	function init(){
		var myMap = new ymaps.Map(mapContainer, {
			center: [60.006934, 30.436683],
			zoom: 15,
			controls: ['zoomControl']
		});

		myMap.behaviors.disable('scrollZoom');

		var mark = new ymaps.Placemark(myMap.getCenter(), {
			hintContent: 'Hisun',
			balloonContent: 'Компания Классика'
		},
		{
			iconLayout: 'default#image',
			iconImageHref: 'img/map-marker.svg',
			iconImageSize: [22.5, 30],
			iconImageOffset: [-5, -38]
		}

		);
		myMap.geoObjects.add(mark);
	};

	// main slider
	const slider = document.querySelector('.main-slider__slider');
	new Swiper(slider, {
		autoplay: true,
		navigation: {
			nextEl: slider.querySelector('.main-slider__btn--next'),
			prevEl: slider.querySelector('.main-slider__btn--prev'),
		},
		pagination: {
			el: '.main-slider__pagination',
			type: 'bullets',
		},
	});



	// sliders
	const mainSliders = document.querySelectorAll('.model__main-slider');
	mainSliders.forEach(mainSlider => {
		const thumbSlider = mainSlider.closest('.model__slider-wrp').querySelector('.model__thumb-slider');

		let swiperMain,
				swiperThumb;

		swiperThumb = new Swiper(thumbSlider, {
			slidesPerView: 'auto',
			spaceBetween: 10,
			slideToClickedSlide: true,

			watchSlidesVisibility: true,
			watchSlidesProgress: true,

			threshold: 10,
			shortSwipes: false
		});

		swiperMain = new Swiper(mainSlider, {
			thumbs: {
				swiper: swiperThumb
			},
			pagination: {
				el: mainSlider.querySelector('.model__main-slider-pagination'),
				clickable: true
			},
			navigation: {
				nextEl: '.model__main-slider-btn--right',
				prevEl: '.model__main-slider-btn--left',
			},
			keyboard: {
				enabled: true,
				onlyInViewport: false,
			},
			slideToClickedSlide: true,
			on: {
				slideChange: function () {
					let activeIndex = this.activeIndex + 1;
				
					let activeSlide = thumbSlider.querySelector(`.swiper-slide:nth-child(${activeIndex})`);
					let nextSlide = thumbSlider.querySelector(`.swiper-slide:nth-child(${activeIndex + 1})`);
					let prevSlide = thumbSlider.querySelector(`.swiper-slide:nth-child(${activeIndex - 1})`);
					
					if (nextSlide && !nextSlide.classList.contains('swiper-slide-visible')) {
							this.thumbs.swiper.slideNext()	
					} else if (prevSlide && !prevSlide.classList.contains('swiper-slide-visible')) {
							this.thumbs.swiper.slidePrev()	
					}
				},
			}
		});
	})

	// Form
	const telephoneInputs = document.querySelectorAll('[data-input-tel]');
	telephoneInputs.forEach(function(telephoneInput){
		IMask(telephoneInput, {
			mask: '+{7}(000)000-00-00'
		});
		telephoneInput.removeAttribute('data-not-inited');
	});


	/*
	* Popups
	*/
	// Close popup when click on close btn, or click outside popup
  const closePopup = popup => {

		if (popup.hasAttribute('data-delete')) {
			popup.remove();
		} else {
			popup.classList.remove('popup--active');
			
			const popupError = popup.querySelector('.popup__error');
			if (popupError?.classList.contains('popup__error--active')) {
				popupError.classList.remove('popup__error--active');
			}
		}

		document.body.style.overflow = '';
	}

	document.addEventListener('click', function(e){
		var tg = e.target;

		if (tg.classList.contains('popup')) {
			closePopup(tg);
			return;
		}

		if (tg.classList.contains('popup__close-btn') || tg.hasAttribute('data-close-popup')) {
			var popup = tg.closest('.popup');
			
			closePopup(popup);
		}
	});
	
	document.addEventListener('keyup', function(e){
		if (e.keyCode == 27) {
			const popup = document.querySelector('.popup--active');
			if (popup) {
				closePopup(popup);
			}
		}		
	});

	// open popups
	document.addEventListener('click', function(e){
		const target = e.target.closest('[data-popup-target]');

		if (target) {			
			const popup = document.querySelector('.'+target.dataset.popupTarget);
			popup.classList.add('popup--active');
			document.body.style.overflow = 'hidden';
		}
	});


	/*
	* Loding more
	*/
	const loadingBtns = document.querySelectorAll('.products__load-more-btn.btn');
	loadingBtns.forEach(btn => {
		btn.addEventListener('click', () => {

			const query = "id="+btn.dataset.id;

			btn.textContent = 'Минуту...';
			btn.disabled = true;

			fetch('/data/moreProducts.html', {
				method: 'POST',
				body: query,
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
				}
			}).then(response => {
				return response.text();
			}).then(html => {
				const list = btn.closest('.products').querySelector('.products__list');
				list.innerHTML += html;
				btn.remove();
			}).catch(function(error) {
				console.error(error);
			});
		})
	})


	// Form validation
	function initSimpleForm(form) {

		var requireElements = form.querySelectorAll('[required], .js-validate'),
			submitBtn = form.querySelector('.js-btn-submit'),
			agreeCheck = form.querySelector('.js-form-check-rules'),
			state = submitBtn.textContent;
	
		function clearFormErrors() {
			var texts = form.querySelectorAll('.booking__form-error:not(.hidden)');
			texts.forEach(function(text){
				text.classList.add('hidden');
			});
	
			var styles = form.querySelectorAll('.has-error');
			styles.forEach(function(style){
				style.classList.remove('has-error');
			});
		}
	
		function removeSingleErrorNode(){
			var isErrorNode = this.classList.contains('has-error');
	
			if (isErrorNode) {
				this.classList.remove('has-error');
				var errorText = this.closest('.booking__form-igroup').querySelector('.booking__form-error');
				if (errorText) {
					errorText.classList.add('hidden');
				}
			}
		}
	
		function scrollToFirstError() {
			var errEl = form.querySelector('.has-error');
	
			if (errEl && !isScrolledIntoView(errEl)) {
				errEl.closest('.booking__form-igroup').scrollIntoView({
					behavior: 'smooth'
				});
			}
		}
	
		function applyAjaxResult(data) {
	
			if (!data.err) {
				if (data.message) {
					var successTarget = form.querySelector('.booking__form-msg');
					successTarget.innerHTML = data.message;
					successTarget.classList.remove('hidden');
				}
			} else {
	
				data.elements.forEach(function(el){
					var errEl = form.querySelector('[name="'+el.errName+'"]');
						if (errEl) {
							errEl.classList.add('has-error');
							var errText = errEl.closest('.booking__form-igroup').querySelector('.booking__form-error');
							errText.textContent = el.message;
							errText.classList.remove('hidden');
						}
				});
	
				scrollToFirstError();
			}
	
			submitBtn.textContent = state;
			submitBtn.disabled = false;
		}
	
		requireElements.forEach(function(el){
			el.addEventListener('input', removeSingleErrorNode);
			el.addEventListener('change', removeSingleErrorNode);
		});
	
		if (agreeCheck) {
			agreeCheck.addEventListener('change', function() {
				submitBtn.disabled = !this.checked;
			});
		}
		
		form.setAttribute('novalidate', '');
		form.addEventListener('submit', function(e){
			e.preventDefault();
	
			clearFormErrors();
	
			var bError = false;
			requireElements.forEach(function(el){
				
				if (!el.checkValidity() || (el.hasAttribute('data-input-tel') && el.value.length < 16) ) {
					var errorType = el.validity.valueMissing ? 'valueMissing' : 'valueInvalid';
					var errorEl = el.closest('.booking__form-igroup').querySelector('.booking__form-error');
					if (errorEl) {
						errorEl.innerHTML = el.dataset[errorType];
						errorEl.classList.remove('hidden');
					}
					el.classList.add('has-error');
	
					bError = true;
				}
			});
			
			if (bError) {
				scrollToFirstError();
				return;
			}
	
			let data = new FormData(form);
			submitBtn.disabled = true;
			submitBtn.textContent = submitBtn.dataset.wait;
	
			fetch(form.getAttribute('action'), {
				method: form.getAttribute('method'),
				body: data
			}).then(response => {
				return response.json();
			}).then(data => {
				applyAjaxResult(data);
			}).catch(function(error) {
				console.error(error);
			});
		});
	}
	
	var forms = document.querySelectorAll('form.js-simple-submit');
	if (!forms.length) {
		return;
	}

	forms.forEach(form => {
		initSimpleForm(form);
	});

})